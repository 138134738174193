import Api from 'easy-fetch-api'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

export const uploadPtoAttachment = (payload) =>
  Api.post({
    url: `/LoanApplication/pto/upload-attachment`,
    data: payload,
  }).catch(console.error)

export const removePtoAttachment = (attachmentId, dispatch) =>
  Api.delete({
    url: `/LoanApplication/pto/delete-attachment`,
    query: { ptoAttachmentId: attachmentId },
  })
    .then(() => {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.POSITIVE,
        title: `Successfully removed attachment.`,
      })
    })
    .catch(console.error)
