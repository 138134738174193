import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'common/hooks'
import moment from 'moment'
import { components, assets, hooks } from '@ElementsCapitalGroup/enium-ui'

import HistoryTracking, {
  HISTORY_TRACKING_TYPES,
} from 'components/history-tracking'
import Button from 'components/button'
import RadioButton from 'components/radio'
import TextField, { INPUT_TYPES } from 'components/input'
import { ERRORS, validate, VALIDATION_TYPES } from 'components/validator'
import {
  dateFormatServer,
  DESKTOP_BREAKPOINT,
  TFA_METHODS,
} from 'common/constants'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { ReactComponent as CheckIcon } from 'assets/check-icon-slim.svg'
import { useStore } from 'store'

import {
  AVAILABLE_USER_STATUS_OPTIONS,
  USER_STATUS_MAP,
  USER_STATUS_OPTIONS,
} from 'modules/users/constants'
import './style.scss'

const { Label, Tooltip, IconButton, Card, Dropdown } = components
const { TrashIcon, PlusIcon, MailIcon, RefreshSingleArrowIcon } = assets
const { useDialogConfirm } = hooks

const UserSettings = ({
  isNew,
  userData,
  onSave,
  onDelete,
  onEmailResend,
  onSendPasswordReset,
  onUpdateEmail,
  organizationOptions,
  roleOptions,
  loading,
}) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    associations: [{ organizationId: '', roleId: '' }],
    isDisabled: false,
    statusId: '',
    tfaMethodId: TFA_METHODS.SMS,
  })
  const { state: currentState } = useStore()
  const isUserPendingOrInactive =
    state.statusId === USER_STATUS_MAP.PENDING ||
    state.statusId === USER_STATUS_MAP.INACTIVE
  const isOriginalUserDisabled =
    userData?.statusId === USER_STATUS_MAP.SUSPENDED
  const [errors, setErrors] = useState({})
  const [somethingChanged, setSomethingChanged] = useState(false)
  const confirm = useDialogConfirm()
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)
  const { userData: currentUser } = currentState.session
  const hasDeleteAccess = hasAccess(currentUser, CLAIMS.CAN_DELETE_USERS)

  useEffect(() => {
    userData &&
      setState({
        ...userData,
        tfaMethodId: userData.tfaMethodId || TFA_METHODS.SMS,
      })
  }, [userData])

  const updateField = (field) => (value) => {
    setState({ ...state, [field]: value })
    if (!somethingChanged) {
      setSomethingChanged(true)
    }
  }

  const removeAssociation = (index) => {
    const associations = state.associations.filter((_, i) => i !== index)
    updateField('associations')(associations)
  }

  const addAssociation = (index) => {
    const associations = [...state.associations]
    associations.splice(index + 1, 0, { organizationId: '', roleId: '' })
    updateField('associations')(associations)
  }

  const onAssociationChange = (field, index) => (ev) => {
    const associations = [...state.associations]
    associations[index][field] = ev.target.value.id
    updateField('associations')(associations)
  }

  const isValid = (user, isUserSuspended) => {
    let [isValid, errors] = validate(VALIDATION_MAP, user)
    errors.associations = [{}]
    const { associations } = user

    // Validate at least 1 association for non-suspended users. Suspended users might not have active associations
    if (!isUserSuspended) {
      if (!associations[0]?.organizationId) {
        errors.associations[0].organizationId = ERRORS.REQUIRED
        isValid = false
      }
      if (!associations[0]?.roleId) {
        errors.associations[0].roleId = ERRORS.REQUIRED
        isValid = false
      }
    }
    for (let i = 0; i < associations.length; i++) {
      for (let j = 0; j < i; j++) {
        if (
          associations[i].organizationId === associations[j].organizationId &&
          associations[i].roleId === associations[j].roleId
        ) {
          errors.associations[i] = {
            organizationId: 'Duplicate pair of (organization, role)',
            roleId: 'Duplicate pair of (organization, role)',
          }
          isValid = false
        }
      }
    }
    setErrors(errors)
    return isValid
  }

  /** On submit new user / update existing user */
  const onUpdateUser = () => {
    const userToSave = {
      ...state,
      associations: state.associations.filter(
        (association) => association.organizationId && association.roleId
      ),
    }
    if (isValid(userToSave, isOriginalUserDisabled)) {
      if (!isNew && userData.emailAddress !== state.emailAddress) {
        confirm({
          title: `Are you sure you want to update the user's email address?`,
          confirmationText: 'Yes',
        })
          .then(() => {
            onUpdateEmail(userToSave).then(() => onSave(userToSave))
          })
          .catch(() => {})
      } else {
        onSave(userToSave)
      }
    }
  }

  const onRemoveUser = () => {
    confirm({ confirmationText: 'Yes' })
      .then(() => onDelete?.(userData.userGuid))
      .catch(() => {})
  }

  const _renderActionButtons = () => {
    if (isNew) {
      return null
    }
    return (
      <div className="org-user__action-buttons">
        {!userData.emailVerified ? (
          <Tooltip title="Resend Email Invitation">
            <IconButton variant="outlined" rounded={true} disabled={loading}>
              <MailIcon onClick={onEmailResend} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Reset Password">
            <IconButton
              disabled={loading}
              rounded={true}
              variant={'outlined'}
              sx={{ borderRadius: '10px', marginRight: '8px' }}
              onClick={onSendPasswordReset}
            >
              <RefreshSingleArrowIcon />
            </IconButton>
          </Tooltip>
        )}
        {hasDeleteAccess && (
          <Tooltip title="Remove User">
            <IconButton variant="outlined" rounded={true} disabled={loading}>
              <TrashIcon onClick={onRemoveUser} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    )
  }

  return (
    <div className="org-user">
      <div className="org-user__header">
        <h1 className="admin-page__header">{isNew ? 'Add' : 'Edit'} User</h1>
        {_renderActionButtons()}
        <Button
          sx={{ padding: '8px 18px' }}
          disabled={!somethingChanged}
          onClick={onUpdateUser}
          loading={loading}
          fullWidth={isTabletView}
        >
          {isNew ? (
            <PlusIcon style={{ width: '16px', marginRight: '8px' }} />
          ) : (
            <CheckIcon style={{ width: '16px', marginRight: '8px' }} />
          )}
          {!isNew ? 'Save Changes' : 'Add User'}
        </Button>
      </div>
      <Card title="User Info" className="org-user__card">
        <div className="org-user__info">
          <TextField
            label="First Name"
            placeholder="First Name"
            value={state.firstName}
            onChange={updateField('firstName')}
            validate={() => errors.firstName}
          />
          <TextField
            label="Last Name"
            placeholder="Last Name"
            value={state.lastName}
            onChange={updateField('lastName')}
            validate={() => errors.lastName}
          />
          <TextField
            label="Email"
            placeholder="Email"
            value={state.emailAddress}
            disabled={userData?.statusId === 1} // Check if it has pending status
            type={INPUT_TYPES.EMAIL}
            onChange={updateField('emailAddress')}
            validate={() => errors.emailAddress}
          />
          <TextField
            label="Phone Number"
            placeholder="Phone Number"
            value={
              state.phoneNumber.startsWith('+1')
                ? state.phoneNumber.slice(2)
                : state.phoneNumber
            }
            type={INPUT_TYPES.PHONE}
            onChange={updateField('phoneNumber')}
            validate={() => errors.phoneNumber}
          />
          {!isNew && (
            <Dropdown
              label="Status"
              disabled={isUserPendingOrInactive}
              value={
                USER_STATUS_OPTIONS.find(
                  (el) => el.id.toString() === state.statusId.toString()
                ) || ''
              }
              options={
                isUserPendingOrInactive
                  ? USER_STATUS_OPTIONS
                  : AVAILABLE_USER_STATUS_OPTIONS
              }
              onChange={(ev) =>
                !isUserPendingOrInactive &&
                updateField('statusId')(ev.target.value.id)
              }
            />
          )}
          <div className="org-user__tfa">
            <Label label="2FA Preference" />
            <div className="align-center" style={{ height: '44px' }}>
              <RadioButton
                label="SMS"
                name="2fa"
                onCheck={() => updateField('tfaMethodId')(TFA_METHODS.SMS)}
                checked={state.tfaMethodId === TFA_METHODS.SMS}
              />
              <RadioButton
                label="Email"
                name="2fa"
                onCheck={() => updateField('tfaMethodId')(TFA_METHODS.EMAIL)}
                checked={state.tfaMethodId === TFA_METHODS.EMAIL}
                style={{ marginLeft: '30px' }}
              />
            </div>
          </div>

          {!isNew && (
            <>
              <TextField
                label="Created By"
                value={userData.createdBy || ''}
                disabled={true}
              />
              <TextField
                label="Date Created"
                value={
                  userData.dateCreated
                    ? moment(userData.dateCreated).format(dateFormatServer)
                    : ''
                }
                disabled={true}
              />
              <TextField
                label="Last Modified By"
                value={userData.modifiedBy || ''}
                disabled={true}
              />
              <TextField
                label="Last Modified Date"
                value={
                  userData.dateLastModified
                    ? moment(userData.dateLastModified).format(dateFormatServer)
                    : ''
                }
                disabled={true}
              />
              <TextField
                label="Date Activated"
                value={
                  userData.dateActivated
                    ? moment(userData.dateActivated).format(dateFormatServer)
                    : ''
                }
                disabled={true}
              />
              {userData.isDisabled && (
                <TextField
                  label="Date Disabled"
                  value={
                    userData.dateInactivated
                      ? moment(userData.dateInactivated).format(
                          dateFormatServer
                        )
                      : ''
                  }
                  disabled={true}
                />
              )}
            </>
          )}
        </div>
      </Card>

      <Card title="Organizations" className="org-user__card">
        {state.associations.length < 1 && (
          <PlusIcon onClick={() => addAssociation(0)} />
        )}
        {state.associations.map((association, index) => {
          const org =
            organizationOptions.find(
              (org) => org.id === association.organizationId
            ) || {}
          const role =
            roleOptions.find((role) => role.id === association.roleId) || {}
          return (
            <div
              key={association.associationId || index}
              className={cx('org-user__association', {
                'org-user__association--disabled': isOriginalUserDisabled,
              })}
            >
              <Dropdown
                label="Organization"
                options={organizationOptions}
                onChange={onAssociationChange('organizationId', index)}
                value={
                  org.label
                    ? org
                    : association.associationId
                    ? { label: 'Admin Level' }
                    : ''
                }
                searchable={true}
                disabled={!org?.label && association.associationId}
                error={!!errors.associations?.[index]?.organizationId}
                helperText={errors.associations?.[index]?.organizationId}
              />
              <Dropdown
                label="Role"
                options={roleOptions}
                onChange={onAssociationChange('roleId', index)}
                value={
                  role.label
                    ? role
                    : association.associationId
                    ? { label: 'Admin Level' }
                    : ''
                }
                searchable={true}
                disabled={!role?.label && association.associationId}
                error={!!errors.associations?.[index]?.roleId}
                helperText={errors.associations?.[index]?.roleId}
              />

              <div className="org-user__actions">
                {state.associations.length > 1 &&
                  ((org.label && role.label) || !association.associationId) && (
                    <TrashIcon onClick={() => removeAssociation(index)} />
                  )}
                <PlusIcon onClick={() => addAssociation(index)} />
              </div>
            </div>
          )
        })}
      </Card>

      {!isNew &&
      hasAccess(currentUser, CLAIMS.CAN_VIEW_LOAN_APPLICATION_HISTORY) ? (
        <Card title="History tracking" className="org-user__card">
          <HistoryTracking
            id={userData.userGuid}
            type={HISTORY_TRACKING_TYPES.USER_DETAILS}
          />
        </Card>
      ) : null}
    </div>
  )
}

UserSettings.propTypes = {
  isNew: PropTypes.bool,
  userData: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onEmailResend: PropTypes.func,
  onUpdateEmail: PropTypes.func,
  onSendPasswordReset: PropTypes.func,
  organizationOptions: PropTypes.array.isRequired,
  roleOptions: PropTypes.array.isRequired,
  loading: PropTypes.bool,
}

export const VALIDATION_MAP = {
  firstName: [VALIDATION_TYPES.REQUIRED],
  lastName: [VALIDATION_TYPES.REQUIRED],
  emailAddress: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.EMAIL],
  phoneNumber: [VALIDATION_TYPES.REQUIRED],
}

export default UserSettings
