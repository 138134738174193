import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { components } from '@ElementsCapitalGroup/enium-ui'

import TextField, { INPUT_TYPES } from 'components/input'
import {
  getFundingFields,
  updateFundingFields,
} from 'modules/organizations/actions.js'
import Button, {
  BUTTON_COLORS,
  BUTTON_VARIANTS,
} from 'components/button/index.js'

import { styles } from '../style'
const { Paper, Checkbox } = components

const friendlyNames = {
  ntpFunding: 'NTP',
  installCompleteFunding: 'Install Complete',
  finalInspectionFunding: 'Final Inspection',
  ptoFunding: 'PTO',
}

const stageKeyToCheckboxState = {
  ntpFunding: 'useNTPFunding',
  installCompleteFunding: 'useInstallCompleteFunding',
  finalInspectionFunding: 'useFinalInspectionFunding',
  ptoFunding: 'usePTOFunding',
}

const OrganizationFunding = ({ orgData, dispatch, setLoading }) => {
  const [remainingValue, setRemainingValue] = useState(100)
  const [stages, setStages] = useState({
    ntpFunding: 0,
    installCompleteFunding: 0,
    finalInspectionFunding: 0,
    ptoFunding: 0,
  })
  const [checkboxStates, setCheckboxStates] = useState({
    useNTPFunding: false,
    useInstallCompleteFunding: false,
    useFinalInspectionFunding: false,
    usePTOFunding: false,
  })

  useEffect(() => {
    setLoading(true)
    getFundingFields(orgData.guid)
      .then((res) => {
        setStages({
          ntpFunding: res.ntpFunding,
          finalInspectionFunding: res.finalInspectionFunding,
          installCompleteFunding: res.installCompleteFunding,
          ptoFunding: res.ptoFunding,
        })
        setCheckboxStates({
          useNTPFunding: res.useNTPFunding,
          useInstallCompleteFunding: res.useInstallCompleteFunding,
          useFinalInspectionFunding: res.useFinalInspectionFunding,
          usePTOFunding: res.usePTOFunding,
        })
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    calculateRemainingValue()
  }, [stages])

  const handlePercentageChange = (stageKey, newValue) => {
    setStages((prev) => ({
      ...prev,
      [stageKey]: newValue,
    }))
  }

  const handleCheckboxChange = (key) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  const calculateRemainingValue = () => {
    const total = Object.values(stages).reduce(
      (sum, percentage) => sum + parseFloat(percentage || 0),
      0
    )
    setRemainingValue(100 - total)
  }

  const isValid = remainingValue === 0

  const onSavePriorities = () => {
    const dataToBeSent = {
      ...stages,
      ...checkboxStates,
      organizationId: orgData.guid,
    }

    setLoading(true)

    updateFundingFields(dataToBeSent, dispatch).finally(() => setLoading(false))
  }

  return (
    <div>
      <Paper style={styles.funding.paper}>
        <div style={styles.funding.headerContainer}>
          <span style={styles.funding.headerTitle}>
            Customize Funding Values
          </span>
          <span style={styles.funding.headerSubtitle}>
            You can adjust and modify each stage’s funding %. The total amount
            of the values should be 100%.
          </span>
        </div>

        <div style={styles.funding.tableHeader}>
          <div style={styles.funding.stageHeader}>Funding Stage</div>
          <div style={styles.funding.percentageHeader}>Percentage</div>
          <div style={styles.funding.inUseHeader}>In Use</div>
        </div>
        {console.log(checkboxStates)}
        {Object.keys(stages).map((stageKey) => (
          <div key={stageKey} style={styles.funding.stageRow}>
            <div style={styles.funding.stageName}>
              {friendlyNames[stageKey]}
            </div>

            <TextField
              type={INPUT_TYPES.PERCENTAGE}
              value={stages[stageKey]}
              onChange={(value) => handlePercentageChange(stageKey, value)}
              fixedDecimalScale={true}
              max="100"
              style={styles.funding.inputField}
            />

            <div style={styles.funding.checkboxContainer}>
              <Checkbox
                size="medium"
                checked={checkboxStates[stageKeyToCheckboxState[stageKey]]}
                onChange={() =>
                  handleCheckboxChange(stageKeyToCheckboxState[stageKey])
                }
              />
            </div>
          </div>
        ))}

        <div
          style={
            remainingValue === 0
              ? styles.funding.remainingValues
              : styles.funding.remainingValuesError
          }
        >
          Remaining Values: {remainingValue}%
        </div>
      </Paper>
      <Button
        variant={BUTTON_VARIANTS.CONTAINED}
        color={BUTTON_COLORS.PRIMARY}
        onClick={onSavePriorities}
        disabled={!isValid}
        style={styles.funding.saveButton}
      >
        Save
      </Button>
    </div>
  )
}

OrganizationFunding.propTypes = {
  orgData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default OrganizationFunding
