import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import moment from 'moment'
import { debounce } from 'lodash'
import StatusComponent, { STATUSES } from 'components/status'
import Table from 'components/table'
import { useMediaQuery } from 'common/hooks'
import { TABLET_BREAKPOINT } from 'common/constants'
import TextInput from 'components/input'
import { useStore } from 'store'
import { useNavigate } from 'react-router-dom'
import {
  createLoanProduct,
  deleteLoanProduct,
  fetchLoanProductList,
} from './actions'

import './index.scss'

const { Button, IconButton } = components

const { createColumnHelper, Dropdown, Dialog, Tooltip } = components
const columnHelper = createColumnHelper()
const { SearchLgIcon, PlusIcon, DuplicateIcon, CopyIcon } = assets

const PRODUCTS_STATUS_ID_MAPPING = {
  0: STATUSES.ACTIVE,
  1: STATUSES.PARTIAL,
  2: STATUSES.UNDEFINED,
}

const StipulationTemplates = () => {
  const { dispatch, state: storeState } = useStore()
  const tableRef = useRef()
  const { lenders } = storeState.orgManagement

  const navigate = useNavigate()
  const isMobileView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const [isDataLoading, setDataLoading] = useState(true)

  const [state, setState] = useState({
    list: [],
    totalPages: 0,
  })

  const [filters, setFilters] = useState({
    sortBy: undefined,
    searchBy: '',
    pageNumber: 0,
    lenderId: undefined,
    itemsPerPage: TEMPLATES_PAGE_SIZE,
  })

  const [copyModalData, setCopyModalData] = useState(null)
  const [rowsSelected, setRowsSelected] = useState(false)
  const noRowsSelected = !Object.keys(rowsSelected)?.length > 0

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'Template Title',
        size: 250,
        cell: (info) => <div className="cell-ellipsis">{info.getValue()}</div>,
      }),
      columnHelper.accessor('lenderId', {
        id: 'lenderId',
        header: 'Lender',
        enableSorting: true,
        size: 250,
        cell: (info) => {
          return (
            <div className="cell-ellipsis">
              {lenders?.find((itm) => itm.guid === info.getValue())?.name}
            </div>
          )
        },
      }),
      columnHelper.accessor('progress', {
        id: 'loanStatusId',
        size: 175,
        header: 'Status',
        enableSorting: true,
        cell: ({ row }) => {
          const status = row.original?.loanStatus

          if (!status) {
            return null
          }

          return (
            <StatusComponent
              status={PRODUCTS_STATUS_ID_MAPPING[status.id]}
              label={status.friendlyName}
            />
          )
        },
      }),
      columnHelper.accessor('dateCreated', {
        id: 'dateCreated',
        header: 'Date Created',
        enableSorting: true,
        size: 175,
        cell: (info) => moment(info.getValue()).format('MMM. Do, YYYY'),
      }),
      columnHelper.accessor('dateLastModified', {
        id: 'dateLastModified',
        header: 'Last Modified',
        enableSorting: true,
        size: 175,
        cell: (info) =>
          info.getValue() && moment(info.getValue()).format('MMM. Do, YYYY'),
      }),
    ]
  }, [lenders])

  useEffect(() => {
    handleFetchTemplates(filters)
  }, [filters])

  const onSearch = (value) => {
    debouncedSearch(value)
  }

  useEffect(() => {
    tableRef.current?.setPageIndex(0)
  }, [filters.searchBy])

  const handleFetchTemplates = (filters) => {
    setDataLoading(true)

    fetchLoanProductList(filters)
      .then((res) => {
        setState({
          list: res?.loanProducts,
          totalPages: Math.ceil(res.itemCount / TEMPLATES_PAGE_SIZE),
        })
      })
      .finally(() => {
        setDataLoading(false)
      })
  }

  const removeTemplate = (loanProductId) => {
    deleteLoanProduct(loanProductId, dispatch).then(() => {
      handleFetchTemplates(filters)
    })
  }
  const fetchData = (options) => {
    return Promise.resolve().then(() => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: filters.sortBy,
        pageNumber: options.pageIndex,
      }))
    })
  }
  const onSortingChange = (data, direction) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortBy: `${data} ${direction}`,
    }))
  }

  const createLoanProductPayload = (loanProduct) => {
    return {
      ...loanProduct,
      stipulationTemplateId:
        loanProduct.stipulationTemplate?.stipulationTemplateId,
      decisioningTemplateId:
        loanProduct.decisioningTemplate?.decisioningTemplateId,
      envelopeDefinitionIds: loanProduct.envelopeDefinitions?.map(
        (ed) => ed.envelopeDefinitionId
      ),
      paymentPeriodsDetails: loanProduct.paymentPeriodsDetails?.map((ppd) => {
        delete ppd.guid
        delete ppd.loanProductId

        return ppd
      }),
    }
  }

  const copyLoanProduct = (lender) => {
    const lenderId = lender.guid
    const data = { ...copyModalData, lenderId }
    const copiedLoanProduct = createLoanProductPayload(data)

    createLoanProduct(copiedLoanProduct, dispatch).then(() =>
      setCopyModalData(null)
    )
  }

  const duplicateLoanProduct = (loanProduct) => {
    setDataLoading(true)
    const duplicatedLoanProduct = createLoanProductPayload(loanProduct)

    createLoanProduct(duplicatedLoanProduct, dispatch).finally(() =>
      setDataLoading(false)
    )
  }

  const debouncedSearch = useCallback(
    debounce((value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchBy: value,
        pageNumber: 0,
      }))
    }, 300),
    []
  )

  const formattedLenders = useMemo(() => {
    return lenders.map((itm) => {
      return {
        id: itm.guid,
        label: itm.name,
      }
    })
  }, [lenders])

  return (
    <div className="admin-page">
      <div className="admin-page__header-wrapper">
        <div>
          <div className="admin-page__header">Products</div>
          <div>View all your Loan Products in one place.</div>
        </div>
        <Button
          startIcon={<PlusIcon />}
          onClick={() => navigate('/loan-product/add-new-product')}
          fullWidth={isMobileView}
          sx={{ mt: isMobileView ? 2 : 0 }}
        >
          Add New Template
        </Button>
      </div>
      <div className="loan-product__filters">
        <TextInput
          onChange={onSearch}
          value={filters.searchBy}
          placeholder={'Search templates'}
          type="search"
          className="input--search"
          disabled={false}
          style={{
            marginBottom: isMobileView ? '16px' : '32px',
            minWidth: 300,
          }}
          startIcon={<SearchLgIcon fontSize="small" />}
        />
        <Dropdown
          options={formattedLenders}
          value={
            formattedLenders.find((itm) => itm.id === filters.lenderId) || ''
          }
          onChange={(ev) => {
            setFilters((prevFilters) => ({
              ...prevFilters,
              lenderId: ev.target.value.id,
            }))
          }}
          fullWidth={false}
          sx={{
            width: isMobileView ? '100%' : '300px',
            alignSelf: 'end',
            marginBottom: isMobileView ? '16px' : '32px',
          }}
        />
      </div>
      {copyModalData && (
        <Dialog
          title={'Copy Loan Product'}
          open={copyModalData}
          PaperProps={{
            sx: {
              height: '500px',
            },
          }}
          onClose={() => setCopyModalData(null)}
        >
          {lenders.map((item, key) => (
            <div
              onClick={() => copyLoanProduct(item)}
              key={key}
              className="loan-product__copy-lenders"
            >
              <div>{item.name}</div>
            </div>
          ))}
        </Dialog>
      )}

      <Table
        data={state.list}
        columns={columns}
        ref={tableRef}
        title={'Loan Products'}
        hasCheckboxes={true}
        selectable={true}
        totalPages={state.totalPages}
        hasPagination={true}
        toolbarRightContent={
          <div className="loan-product__buttons">
            <Tooltip title="Duplicate">
              <IconButton
                disabled={noRowsSelected}
                variant={'outlined'}
                sx={{ borderRadius: '10px', marginRight: '8px' }}
                onClick={() => {
                  duplicateLoanProduct(
                    tableRef.current?.table.getRow(
                      Object.keys(
                        tableRef.current?.table?.getState().rowSelection
                      )?.[0]
                    ).original
                  )
                }}
              >
                <DuplicateIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy">
              <IconButton
                disabled={noRowsSelected}
                variant={'outlined'}
                sx={{ borderRadius: '10px' }}
                onClick={() => {
                  setCopyModalData(
                    tableRef.current?.table.getRow(
                      Object.keys(
                        tableRef.current?.table?.getState().rowSelection
                      )[0]
                    ).original
                  )
                }}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
        onSortingChange={onSortingChange}
        fetchData={fetchData}
        onRowsRemove={(data, info, row) => {
          removeTemplate(row?.[0]?.loanProductId)
        }}
        onRowsEdit={(ev, row) => {
          window.open(`/loan-product/${row?.[0]?.loanProductId}`, '_blank')
        }}
        onRowClick={(ev, row) => {
          window.open(`/loan-product/${row?.original.loanProductId}`, '_blank')
        }}
        dataLoading={isDataLoading}
        onTableRowsSelectChange={setRowsSelected}
        emptyTitle="No Loan Products Found"
        emptyDescription="We couldn't find any data matching your criteria."
      />
    </div>
  )
}

export default StipulationTemplates

export const TEMPLATES_PAGE_SIZE = 7
