import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { components } from '@ElementsCapitalGroup/enium-ui/'
import PropTypes from 'prop-types'
import { setLoading } from 'modules/global/actions'
import EmptyList from 'components/empty-list'

import LazyLoader from 'components/lazy-loader'

import { HISTORY_PAGE_SIZE } from './constants'
import { transformHistoryDataForTimeline } from './utils'
import './style.scss'

export const AdminHistoryTab = ({ dispatch, fetchHistory }) => {
  const [page, setPage] = useState(0)
  const [adminHistoryTab, setAdminHistoryTab] = useState({})

  const { Timeline } = components

  // /** Method that fetches the next page of items */
  const fetchNextPage = useCallback(
    (nextPage, pageSize, filters) => {
      return fetchHistory(nextPage, pageSize, filters).then((newItems) => {
        const transformedNewItems = transformHistoryDataForTimeline(newItems)

        setAdminHistoryTab((prev) => [...prev, ...transformedNewItems])
        setPage(nextPage)

        return newItems
      })
    },
    [page]
  )

  const initComponent = async () => {
    setLoading(dispatch, true)

    try {
      const history = await fetchHistory(page, HISTORY_PAGE_SIZE)
      setAdminHistoryTab(transformHistoryDataForTimeline(history))
    } catch (e) {
      console.error(e)
    }

    setLoading(dispatch, false)
  }

  useEffect(() => {
    initComponent()
  }, [])

  if (!adminHistoryTab.length) {
    return (
      <EmptyList
        title="No History Logs Found"
        description="We couldn't find any data."
      />
    )
  }
  return (
    <>
      {adminHistoryTab.length > 0 && (
        <div>
          <div className="history__paper">
            <Timeline logs={adminHistoryTab} />
          </div>
        </div>
      )}
      {!isEmpty(adminHistoryTab) ? (
        <LazyLoader fetchNextPage={fetchNextPage} page={page} />
      ) : null}
    </>
  )
}

AdminHistoryTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchHistory: PropTypes.func.isRequired,
}
