import React, { useMemo, useState } from 'react'
import { useStore } from 'store'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { DESKTOP_BREAKPOINT, dateFormatUS } from 'common/constants'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'

import Table from 'components/table'
import Button, { BUTTON_SIZES } from 'components/button/index'
import { formatDate } from 'common/date'
import { useMediaQuery } from 'common/hooks'
import FileUploadV2 from 'components/file-upload-v2'
import { assets, components, colors } from '@ElementsCapitalGroup/enium-ui'
import { bulkGrandFunds, uploadFile } from 'modules/automated-funding/actions'
import { styles as uploadedStyles } from 'modules/loan-application/ntp/uploaded-stipulation/style'

import * as styles from './styles'

const { Paper, createColumnHelper, IconButton, Tooltip, Chip } = components
const { DownloadIcon, TrashIcon02 } = assets

const BulkFunding = () => {
  const { dispatch } = useStore()
  const [bulkFundings, setBulkFundings] = useState([])
  const [fileToBeUploaded, setFileToBeUploaded] = useState({})
  const [loading, setLoading] = useState(false)
  const [selectedFundings, setSelectedFundings] = useState([])
  const isMobileView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const { t: translate } = useTranslation()
  const columnHelper = createColumnHelper()

  const columns = useMemo(
    () => [
      columnHelper.accessor('applicantsName', {
        header: translate('bulkFunding.applicantName'),
      }),
      columnHelper.accessor('fundingType.friendlyName', {
        header: translate('bulkFunding.fundingType'),
        cell: ({ row }) => {
          return (
            <>
              <Chip
                size="small"
                label={row?.original?.fundingType?.friendlyName}
                style={{
                  color:
                    row?.original?.fundingType?.friendlyName === 'Initial'
                      ? colors.blue[700]
                      : colors.green[700],
                  background:
                    row?.original?.fundingType?.friendlyName === 'Initial'
                      ? colors.blue[100]
                      : colors.green[50],
                }}
              />
            </>
          )
        },
      }),
      columnHelper.accessor('fundingAmount', {
        header: translate('bulkFunding.grossAmount'),
        cell: ({ row }) => `$${row.original.fundingAmount.toFixed(2)}`,
      }),

      columnHelper.accessor('netFundingAmount', {
        header: translate('bulkFunding.netAmount'),
        cell: ({ row }) => `$${row.original.netFundingAmount.toFixed(2)}`,
      }),
      columnHelper.accessor('fundingFormattedDate', {
        header: translate('bulkFunding.fundingDate'),
      }),
      columnHelper.accessor('valid', {
        header: translate('bulkFunding.valid'),
        cell: ({ row }) => {
          return (
            <>
              <Chip
                size="small"
                label={row.original.valid.toString()}
                style={{
                  color: !row.original.valid
                    ? colors.red[700]
                    : colors.green[700],
                  background: !row.original.valid
                    ? colors.red[50]
                    : colors.green[50],
                }}
              />
            </>
          )
        },
      }),
    ],
    [bulkFundings, translate]
  )

  const amounts = useMemo(
    () => ({
      totalAmount: bulkFundings
        .reduce((prev, current) => prev + current.fundingAmount, 0)
        .toFixed(2),
      totalSelectedAmount: selectedFundings
        .reduce((prev, current) => prev + current.fundingAmount, 0)
        .toFixed(2),
      totalNetAmount: bulkFundings
        .reduce((prev, current) => prev + current.netFundingAmount, 0)
        .toFixed(2),
      totalSelectedNetAmount: selectedFundings
        .reduce((prev, current) => prev + current.netFundingAmount, 0)
        .toFixed(2),
    }),
    [selectedFundings, bulkFundings]
  )

  const onCSVSelected = (file) => {
    if (!file) {
      return
    }
    setLoading(true)
    setFileToBeUploaded(file[0])
    uploadFile(file[0])
      .then((fundings) => {
        const parsedFundings = fundings.map((funding) => {
          const valid = !funding.errorMessages || !funding.errorMessages.length
          return {
            ...funding,
            fundingFormattedDate: formatDate(
              funding.fundingDate,
              'MM/DD/YYYY hh:mm:ss'
            ),
            selected: valid,
            valid,
          }
        })
        setBulkFundings(parsedFundings)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  const submitFunds = () => {
    const loanApps = selectedFundings.map((loanApp) => ({
      loanApplicationId: loanApp.loanApplicationId,
      applicantsName: loanApp.applicantsName,
      fundingDate: loanApp.fundingDate,
      fundingTypeId: loanApp.fundingType.id,
      fundingAmount: loanApp.fundingAmount,
      netFundingAmount: loanApp.netFundingAmount,
    }))
    if (!loanApps.length) {
      return
    }

    setLoading(true)
    bulkGrandFunds(loanApps)
      .then((response) => {
        const errors = response.filter((el) => el.errorMessages?.length)
        if (!errors.length) {
          showNotification(dispatch, {
            message: translate('bulkFunding.submitSuccessMessage'),
          })
        } else {
          errors.forEach((error) =>
            showNotification(dispatch, {
              message: `${error.loanApplicationId}: ${error.errorMessages[0]}`,
              type: NOTIFICATION_TYPES.NEGATIVE,
            })
          )
        }
      })
      .catch((err) =>
        showNotification(dispatch, {
          message: err,
          type: NOTIFICATION_TYPES.NEGATIVE,
        })
      )
      .finally(() => setLoading(false))
  }

  const onFileRemove = () => {
    setFileToBeUploaded({})
    setBulkFundings([])
  }

  const _renderTotals = () => (
    <div
      style={isMobileView ? styles.totalsWrapperMobile : styles.totalsWrapper}
    >
      <Paper
        className="paper paper--no-shadow-box"
        sx={isMobileView ? styles.paperNoShadowMobile : styles.paperNoShadow}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={styles.totalColumn}>
            <span>{translate('bulkFunding.totalGrossAmount')}</span>
            <div>
              <div>
                <div style={styles.totalAmount}>${amounts.totalAmount}</div>
              </div>
            </div>
          </div>
          <div style={styles.totalColumn}>
            <span>{translate('bulkFunding.totalNetAmount')}</span>
            <div>
              <div>
                <div>
                  <div style={styles.totalAmount}>
                    ${amounts.totalNetAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <Paper
        className="paper"
        sx={isMobileView ? styles.totalsPaperMobile : styles.totalsPaper}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={styles.totalColumn}>
            <span>{translate('bulkFunding.totalSelectedGrossAmount')}</span>
            <div>
              <div>
                <div style={styles.totalAmount}>
                  ${amounts.totalSelectedAmount}
                </div>
              </div>
            </div>
          </div>
          <div style={styles.totalColumn}>
            <span>{translate('bulkFunding.totalSelectedNetAmount')}</span>
            <div>
              <div>
                <div style={styles.totalAmount}>
                  ${amounts.totalSelectedNetAmount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  )

  return (
    <div>
      <div>
        <Paper className="paper paper--no-shadow-box" sx={styles.paperPadding}>
          <FileUploadV2
            key="monitoring"
            multiple={true}
            canUpload={true}
            onChange={onCSVSelected}
            title={translate('bulkFunding.csvTitle')}
          />
          {fileToBeUploaded?.mimeType && (
            <Paper style={uploadedStyles.paper}>
              <div style={uploadedStyles.wrapper}>
                <div style={uploadedStyles.attachmentInfoWrapper}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DownloadIcon style={uploadedStyles.attachmentInfo} />
                    <div style={uploadedStyles.attachmentInfo}>
                      <div style={uploadedStyles.attachmentName}>
                        {fileToBeUploaded.name}
                      </div>
                      <div style={uploadedStyles.attachmentDate}>
                        {moment(fileToBeUploaded.dateCreated).format(
                          dateFormatUS
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="ntp-upload">
                    <Tooltip title={translate('bulkFunding.deleteTooltip')}>
                      <IconButton
                        size={BUTTON_SIZES.SMALL}
                        onClick={() => {}}
                        sx={{ ml: 2 }}
                      >
                        <TrashIcon02
                          sx={{ marginBottom: '3px' }}
                          onClick={onFileRemove}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Paper>
          )}
        </Paper>
      </div>

      {bulkFundings.length > 0 && (
        <Table
          data={bulkFundings}
          hideRemoveButton={true}
          style={{
            marginTop: '24px',
            boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          }}
          columns={columns}
          multiRowSelection={true}
          title="Bulk Fundings"
          hideEditButton={true}
          onTableRowsSelectChange={setSelectedFundings}
          hasCheckboxes={true}
        />
      )}

      {bulkFundings?.length ? _renderTotals() : null}

      <Button
        onClick={submitFunds}
        loading={loading}
        disabled={selectedFundings.length === 0}
        sx={{ mt: 2 }}
      >
        {translate('buttons.submit')}
      </Button>
    </div>
  )
}

export default BulkFunding
